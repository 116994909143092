import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export const accommodation = [
  { text: $gettext('Renting'), value: 'rental' },
  { text: $gettext('Owner-occupied house'), value: 'own' },
  { text: $gettext('Partowner-occupied house'), value: 'partialOwnership' },
  { text: $gettext('With parents or acquaintances'), value: 'parentOrRelative' },
  { text: $gettext('Other'), value: 'other' },
];

export const accommodationType = [
  { text: $gettext('Town house'), value: 'ownHouse' },
  { text: $gettext('Terraced house'), value: 'rowHouse' },
  { text: $gettext('Apartment house'), value: 'appartment' },
  { text: $gettext('Other'), value: 'other' },
];

export const education = [
  { text: $gettext('Primary school'), value: 'elementary' },
  { text: $gettext('Trade School'), value: 'careerCollege' },
  { text: $gettext('High School'), value: 'highSchool' },
  { text: $gettext('University, Bachelor level'), value: 'bachelorUniversityDegree' },
  { text: $gettext('University, Master level'), value: 'masterUniversityDegree' },
  { text: $gettext('University, Postgraduate level'), value: 'postgraduateUniversityDegree' },
];

export const income = [
  { text: $gettext('Under 5000 kr'), value: '0-500000' },
  { text: '5001 - 10 000 kr', value: '500100-1000000' },
  { text: '10 001 - 15 000 kr', value: '1000100-1500000' },
  { text: '15 001 - 20 000 kr', value: '1500100-2000000' },
  { text: '20 001 - 25 000 kr', value: '2000100-2500000' },
  { text: '25 001 - 30 000 kr', value: '2500100-3000000' },
  { text: '30 001 - 35 000 kr', value: '3000100-3500000' },
  { text: '35 001 - 40 000 kr', value: '3500100-4000000' },
  { text: '40 001 - 45 000 kr', value: '4000100-4500000' },
  { text: '45 001 - 50 000 kr', value: '4500100-5000000' },
  { text: $gettext('Over 50 000 kr'), value: '5000000+' },
];

export const incomeTypes = [
  { label: $gettext('Salary'), name: 'salary' },
  { label: $gettext('Student grant'), name: 'studentAllowance' },
  { label: $gettext('Social Benefits'), name: 'monthlyIncomeWelfare' },
  { label: $gettext('Rental Support'), name: 'rentSupport' },
  { label: $gettext('Pension'), name: 'pension' },
  { label: $gettext('Unemployment benefit'), name: 'unemploymentAid' },
  { label: $gettext('Other income'), name: 'monthlyIncomeOther' },
];

export const openLoanTypes = [
  { label: $gettext('Mortgage'), name: 'mortgage' },
  { label: $gettext('Student loan'), name: 'studentLoan' },
  { label: $gettext('Credit card loan'), name: 'creditCardLoan' },
  { label: $gettext('Car loan'), name: 'carLoan' },
  { label: $gettext('Consumer credit'), name: 'consumerLoan' },
  { label: $gettext('Payday loan'), name: 'paydayLoan' },
  { label: $gettext('Other'), name: 'otherLoan' },
];

export const purpose = [
  { text: $gettext('Drivers Licence'), value: 'driversLicence' },
  { text: $gettext('Car / boat / motorcycle'), value: 'vehicle' },
  { text: $gettext('Wedding'), value: 'wedding' },
  { text: $gettext('Household Purchases'), value: 'householdExpenses' },
  { text: $gettext('Consumption'), value: 'consumption' },
  { text: $gettext('Loan consolidation'), value: 'mergingOfLoans' },
  { text: $gettext('Pet'), value: 'pet' },
  { text: $gettext('Doctor'), value: 'doctor' },
  { text: $gettext('Travelling'), value: 'travel' },
  { text: $gettext('Other Household expenses'), value: 'additionalLivingExpenses' },
  { text: $gettext('Studying'), value: 'study' },
  { text: $gettext('Home Decoration'), value: 'renovation' },
  { text: $gettext('Taxes'), value: 'taxes' },
  { text: $gettext('Corporate loan'), value: 'businessLoan' },
  { text: $gettext('Something else'), value: 'other' },
];

export const sizeOfFamily = [
  { text: $gettext('1 adult'), value: '1-0' },
  { text: $gettext('1 adult and 1 child'), value: '1-1' },
  { text: $gettext('1 adult and 2 children'), value: '1-2' },
  { text: $gettext('1 adult and 3 or more children'), value: '1-3+' },
  { text: $gettext('2 adults'), value: '2-0' },
  { text: $gettext('2 adults and 1 child'), value: '2-1' },
  { text: $gettext('2 adults and 2 children'), value: '2-2' },
  { text: $gettext('2 adults and 3 or more children'), value: '2-3+' },
  { text: $gettext('Other'), value: 'other' },
];

export const workRelationshipType = [
  { text: $gettext('Employee'), value: 'employee' },
  { text: $gettext('Part time'), value: 'parttime' },
  { text: $gettext('Lower degree clerk'), value: 'lowerDegreeOfficial' },
  { text: $gettext('Senior degree clerk or manager position'), value: 'higherDegreeOfficial' },
];

export const workSituation = [
  { text: $gettext('Permanent'), value: 'steady' },
  { text: $gettext('Temporary'), value: 'fixed-period' },
  { text: $gettext('Entrepreneur'), value: 'entrepreneur' },
  { text: $gettext('Student'), value: 'student' },
  { text: $gettext('Retired'), value: 'retired' },
  { text: $gettext('Early retirement'), value: 'earlyRetirement' },
  { text: $gettext('Unemployed'), value: 'unemployed' },
  { text: $gettext('Other'), value: 'other' },
];
