<template>
  <cw-page
    icon="person"
    class="cw-customer-questions"
  >
    <template #hero-title>
      <translate>
        You will receive a personal loan offer.
      </translate>
    </template>
    <template #hero-text>
      <translate>
        The answers you give will affect your loan offer, so please respond carefully.
      </translate>
    </template>

    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row>
        <v-col cols="12">
          <h2 class="text-h6">
            <translate>
              Residency
            </translate>
          </h2>
        </v-col>
        <v-col cols="12" sm="6">
          <cw-select
            id="accommodation"
            v-model="form.accommodation"
            :items="items.accommodation"
            :hint="accommodationHint"
            :label="accommodationLabel"
            rules="required"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <cw-select
            id="accommodationType"
            v-model="form.accommodationType"
            :items="items.accommodationType"
            :hint="accommodationTypeHint"
            :label="accommodationTypeLabel"
            rules="required"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <cw-select
            id="sizeOfFamily"
            v-model="form.sizeOfFamily"
            :items="items.sizeOfFamily"
            :label="sizeOfFamilyLabel"
            rules="required"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2 class="text-h6 mt-5">
            <translate>
              Education and Employment
            </translate>
          </h2>
        </v-col>

        <v-col cols="12" sm="6">
          <cw-select
            id="education"
            v-model="form.education"
            :items="items.education"
            :hint="educationHint"
            :label="educationLabel"
            rules="required"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <cw-select
            id="workSituation"
            v-model="form.workSituation"
            :items="items.workSituation"
            :label="workSituationLabel"
            rules="required"
          />
        </v-col>

        <transition name="slide-y-transition">
          <v-col
            v-if=" ['steady', 'fixed-period'].includes(form.workSituation)"
            class="pa-0"
            cols="12"
          >
            <v-row>
              <v-col cols="12" sm="6">
                <cw-select
                  id="workRelationshipType"
                  v-model="form.workRelationshipType"
                  :items="items.workRelationshipType"
                  :label="workRelationshipTypeLabel"
                  rules="required"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <cw-text-field
                  id="employerName"
                  v-model="form.employerName"
                  :hint="employerNameHint"
                  :label="employerNameLabel"
                  rules="required"
                  type="text"
                  anonymize
                />
              </v-col>

              <v-col cols="12" sm="6">
                <cw-date-picker
                  id="employmentStartDate"
                  v-model="form.employmentStartDate"
                  :label="employmentStartDateLabel"
                  prepend-icon="event"
                  rules="required"
                  type="month"
                />
              </v-col>
            </v-row>
          </v-col>
        </transition>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2 class="text-h6 mt-5">
            <translate>
              Financial situation
            </translate>
          </h2>
        </v-col>

        <v-col cols="12" sm="6">
          <cw-select
            id="personalIncome"
            v-model="form.personalIncome"
            :items="items.income"
            :label="personalIncomeLabel"
            rules="required|notNegativeNumber"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <cw-select
            id="householdIncomeCombined"
            v-model="form.householdIncomeCombined"
            :items="items.income"
            :label="householdIncomeCombinedLabel"
            rules="required|notNegativeNumber"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <cw-text-field
            id="assetsTotal"
            v-model="form.assetsTotal"
            :hint="assetsTotalHint"
            :label="assetsTotalLabel"
            lang="en-150"
            rules="required|notNegativeNumber"
            type="number"
            currency-input
          />
        </v-col>

        <v-col cols="12" sm="6">
          <cw-text-field
            id="otherExpenses"
            v-model="form.otherExpenses"
            :hint="otherExpensesHint"
            :label="otherExpensesLabel"
            lang="en-150"
            rules="required|notNegativeNumber"
            type="number"
            currency-input
          />
        </v-col>
      </v-row>
      <validation-observer ref="incomeTypes">
        <template #default="{ invalid, validated, validate }">
          <cw-checkbox-group
            id="incomeTypes"
            v-model="form.incomeTypes"
            :form="form"
            :items="items.incomeTypes"
            :valid="!validated || (validated && !invalid)"
            :title="incomeTypesTitle"
            @validate-group="validate"
          />
        </template>
      </validation-observer>

      <v-row>
        <v-col cols="12">
          <h2 class="text-h6 mt-5">
            <translate>
              Loans
            </translate>
          </h2>
        </v-col>

        <v-col cols="12" sm="6">
          <cw-text-field
            id="loansTotal"
            v-model="form.loansTotal"
            :hint="loansTotalHint"
            :label="loansTotalLabel"
            lang="en-150"
            rules="required|notNegativeNumber"
            type="number"
            currency-input
          />
        </v-col>

        <transition name="slide-y-transition">
          <v-col v-if="form.loansTotal > 0" cols="12" sm="6">
            <cw-text-field
              id="instalmentPerMonth"
              v-model="form.instalmentPerMonth"
              :hint="instalmentPerMonthHint"
              :label="instalmentPerMonthLabel"
              lang="en-150"
              rules="required|notNegativeNumber"
              type="number"
              currency-input
            />
          </v-col>
        </transition>

        <transition name="slide-y-transition">
          <v-col v-if="form.loansTotal > 0" class="pa-0" cols="12">
            <validation-observer ref="openLoanTypes">
              <template #default="{ invalid, validated, validate }">
                <cw-checkbox-group
                  id="openLoanTypes"
                  v-model="form.openLoanTypes"
                  :form="form"
                  :items="items.openLoanTypes"
                  :valid="!validated || (validated && !invalid)"
                  :title="openLoanTypesTitle"
                  @validate-group="validate"
                />
              </template>
            </validation-observer>
          </v-col>
        </transition>

        <v-col
          class="pb-0"
          cols="12"
        >
          <h3 class="text-body-2 cw-customer-questions__purpose">
            <translate>
              Purpose of the new loan
            </translate>
          </h3>
        </v-col>

        <v-col
          class="cw-customer-questions__purpose"
          sm="6"
          cols="12"
        >
          <cw-select
            id="purpose"
            v-model="form.purpose"
            :items="items.purpose"
            :label="purposeLabel"
            rules="required"
          />
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Continue
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';
import * as items from '@se/pages/steps/data/customerQuestions';

export default {
  name: 'CwCustomerQuestions',

  components: {
    'cw-checkbox-group': () => import('@shared/components/inputs/CwCheckboxGroup'),
    'cw-date-picker': () => import('@shared/components/inputs/CwDatePicker'),
    'cw-select': () => import('@shared/components/inputs/CwSelect'),
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    form: {
      accommodation: '',
      accommodationType: '',
      assetsTotal: null,
      education: '',
      employerName: '',
      employmentStartDate: '',
      householdIncomeCombined: null,
      incomeTypes: null,
      instalmentPerMonth: null,
      loansTotal: null,
      openLoanTypes: null,
      otherExpenses: null,
      personalIncome: '',
      purpose: '',
      sizeOfFamily: '',
      workSituation: '',
      workRelationshipType: '',
    },
    items,
  }),

  computed: {
    ...mapState({
      loanType: state => state.application.product.loanType,
    }),

    /**
     * Gettext translations
     */
    accommodationHint: vm => vm.$gettext('The main form of housing'),
    accommodationLabel: vm => vm.$gettext('Form of housing'),
    accommodationTypeHint: vm => vm.$gettext('The main type of housing'),
    accommodationTypeLabel: vm => vm.$gettext('Housing Type'),
    sizeOfFamilyLabel: vm => vm.$gettext('Household size'),
    educationHint: vm => vm.$gettext('The latest degree'),
    educationLabel: vm => vm.$gettext('Education'),
    workSituationLabel: vm => vm.$gettext('Employment situation'),
    workRelationshipTypeLabel: vm => vm.$gettext('Type of employment'),
    employerNameHint: vm => vm.$gettext('Principal employer'),
    employerNameLabel: vm => vm.$gettext('Employer'),
    employmentStartDateLabel: vm => vm.$gettext('Employment began'),
    personalIncomeLabel: vm => vm.$gettext('Personal income'),
    householdIncomeCombinedLabel: vm => vm.$gettext('Household income'),
    assetsTotalHint: vm => vm.$gettext('Total amount of personal property. A property is a car, shares, apartment, boat, summer cottage, or other valuable property.'),
    assetsTotalLabel: vm => vm.$gettext('Property'),
    otherExpensesHint: vm => vm.$gettext('The amount of personal monthly expenses (rent, consideration, electricity, network connection, telephone, etc.).'),
    otherExpensesLabel: vm => vm.$gettext('Expenses'),
    incomeTypesTitle: vm => vm.$gettext('Personal sources of income'),
    loansTotalHint: vm => vm.$gettext('Amount of personal loans (small loans, mortgage, car loan, credit card loans, etc.).'),
    loansTotalLabel: vm => vm.$gettext('Loans'),
    instalmentPerMonthHint: vm => vm.$gettext('Aggregate repayment of loans (estimate).'),
    instalmentPerMonthLabel: vm => vm.$gettext('Instalment'),
    openLoanTypesTitle: vm => vm.$gettext('Open loans'),
    purposeLabel: vm => vm.$gettext('Intended Use'),
  },
  methods: {
    ...mapActions({
      submitForm: 'application/submit',
    }),

    /**
     * Prepare form data for submit
     */
    prepareFormData() {
      // build params based on the form values
      const params = { ...this.form };

      // remove unfilled fields from params
      Object.keys(params).forEach((key) => {
        if (params[key] === '' || params[key] === null) {
          delete params[key];
        }
      });

      return params;
    },

    async validateForm() {
      const observers = [
        this.$refs.observer.validate(),
        this.$refs.incomeTypes.validate(),
      ];

      if (Object.keys(this.$refs).includes('openLoanTypes')) {
        observers.push(this.$refs.openLoanTypes.validate());
      }

      // validate all the observers
      const valid = await Promise.all(observers);
      this.$dataLayer.PushToECommerce({ params: { action: 'stepValidationClick' } });

      DataLayer.onValidationClick();

      if (!valid.every(v => v)) {
        this.goToError();
        DataLayer.onValidationFail();

        return;
      }

      this.$dataLayer.PushToECommerce({ params: { action: 'stepValidationSubmit' } });
      this.$dataLayer.PushToECommerce({ params: { action: 'infoExtra', formData: this.form } });
      await this.submit();
    },

    /**
     * Submit step data
     */
    async submit() {
      this.$wait.start('SUBMIT_FORM');

      const formParams = this.prepareFormData();
      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Submit Customer Questions',
      };

      try {
        await this.submitForm({
          formParams,
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
